import React from 'react';
import { useStore } from '../App';
import Loader from '../icons/loader';

const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const longMonths = ['January', 'February', 'March', 'April', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const shortDateFormat = (d: Date) => `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear() % 100}`;
const mediumDateFormat = (d: Date) => `${shortMonths[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
const longDateFormat = (d: Date) => `${longMonths[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
const fullDateFormat = (d: Date) => `${daysOfTheWeek[d.getDay()]}, ${longMonths[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
const dateFormats = [shortDateFormat, mediumDateFormat, longDateFormat, fullDateFormat];

const secondSundayInMarch = (() => {
	const earliestPossibleStart = new Date();
	earliestPossibleStart.setMonth(2);
	earliestPossibleStart.setDate(8);

	// if the earliest possible day is not a sunday
	if (earliestPossibleStart.getDay() !== 0) {
		// set date to following sunday
		earliestPossibleStart.setDate(8 + (7 - earliestPossibleStart.getDay()));
	}

	return earliestPossibleStart;
})();

const firstSundayInNovember = (() => {
	const earliestPossibleEnd = new Date();
	earliestPossibleEnd.setMonth(10);
	earliestPossibleEnd.setDate(1);

	if (earliestPossibleEnd.getDay() !== 0) {
		earliestPossibleEnd.setDate(1 + (7 - earliestPossibleEnd.getDay()));
	}

	return earliestPossibleEnd;
})();

const isDST = (d = new Date()) => secondSundayInMarch < d && d < firstSundayInNovember; 

const p = (n: number) => n.toString().padStart(2, '0');
const shortTimeFormat = (d: Date) => `${d.getHours() % 12}:${p(d.getMinutes())} ${d.getHours() < 12 ? 'AM' : 'PM'}`;
const mediumTimeFormat = (d: Date) => `${d.getHours() % 12}:${p(d.getMinutes())}:${p(d.getSeconds())} ${d.getHours() < 12 ? 'AM' : 'PM'}`;
const longTimeFormat = (d: Date) => `${mediumTimeFormat(d)} ${isDST() ? 'CDT' : 'CST'}`; // this only works for central time
const fullTimeFormat = (d: Date) => `${mediumDateFormat(d)} ${isDST() ? 'Central Daylight Time' : 'Central Standard Time'}`;
const timeFormats = [shortTimeFormat, mediumTimeFormat, longTimeFormat, fullTimeFormat];

function HomePage() {
	const [configuration, setConfiguration] = useStore(state => [state.configuration, state.setConfiguration]);
	const [error, setError] = React.useState('');
	const [saveDate, setSaveDate] = React.useState(null as Date | null);
	const [isSaving, setSaving] = React.useState(false);
	const [time, setTime] = React.useState(new Date());

	const dateStyleRef = React.createRef<HTMLSelectElement>();
	const fileSelect = React.createRef<HTMLInputElement>();
	const timeStyleRef = React.createRef<HTMLSelectElement>();

	React.useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		const target = e.target as HTMLFormElement;

		const dateStyle = target[0] as HTMLSelectElement;
		const timeStyle = target[1] as HTMLSelectElement;
		const video = target[2] as HTMLInputElement;
		const file = video?.files?.item(0);

		if (dateStyle && timeStyle && file) {
			const data = new FormData();
			data.set('dateStyle', dateStyle.value);
			data.set('timeStyle', timeStyle.value);
			data.set('video', file);

			setSaving(true);
			setConfiguration(data)
				.then(() => {
					setError('');
					setSaveDate(new Date());
					document.querySelector('video')?.load();
				})
				.catch(() => {
					setError('Failed to save configuration');
					setSaveDate(null);
				})
				.finally(() => setSaving(false));
		} else {
			setError('Unable to read configuration');
		}
	};

	const [formattedTime, setFormattedTime] = React.useState('');
	const [formattedDate, setFormattedDate] = React.useState('');

	const [isValid, setIsValid] = React.useState(false);

	React.useEffect(() => {
		setFormattedDate(dateFormats[['short', 'medium', 'long', 'full'].indexOf(dateStyleRef.current?.value ?? configuration?.dateStyle ?? '')](time));
		setFormattedTime(timeFormats[['short', 'medium', 'long', 'full'].indexOf(timeStyleRef.current?.value ?? configuration?.timeStyle ?? '')](time));
	}, [time]);

	return (
		<div className="w-4/5 max-w-[36rem] mx-auto py-8">
			<div className="relative mb-8">
				<div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-6 h-6 w-36 bg-gray-300 rounded-b-sm"></div>
				<video className="rounded shadow-xl ring-8 ring-gray-300" src="https://api.signage.pensacolachs.org/source/video.mov" autoPlay loop />
				<div className="absolute bottom-0 left-0 min-w-48 h-16 ml-2 mb-2 px-2 rounded bg-black/50 flex flex-col justify-around">
					<p className="text-white font-bold text-xl">{formattedTime}</p>
					<p className="text-white font-bold text-xl">{formattedDate}</p>
				</div>
			</div>

			<form className="mt-4" onSubmit={onSubmit}>
				<div className="flex items-center justify-evenly">
					<div>
						<label htmlFor="dateStyle" className="ml-6 mr-4">Date style</label>
						<select className="shadow rounded-lg p-2 ring-1" ref={dateStyleRef} onChange={() => setTime(new Date())} name="dateStyle" defaultValue={configuration?.dateStyle ?? 'long'}>
							<option value="short">Short</option>
							<option value="medium">Medium</option>
							<option value="long">Long</option>
							<option value="full">Full</option>
						</select>
					</div>

					<div>
						<label htmlFor="timeStyle" className="ml-6 mr-4">Time style</label>
						<select className="shadow rounded-lg p-2 ring-1" ref={timeStyleRef} onChange={() => setTime(new Date())} name="timeStyle" defaultValue={configuration?.timeStyle ?? 'medium'}>
							<option value="short">Short</option>
							<option value="medium">Medium</option>
							<option value="long">Long</option>
							<option value="full">Full</option>
						</select>
					</div>
				</div>

				<input type="file" ref={fileSelect} onChange={() => setIsValid(!isSaving && fileSelect.current?.files?.length === 1)} className="block mt-2 mx-auto p-2 file:mr-4 file:bg-white file:ring-1 file:cursor-pointer file:border-0 file:p-2 file:rounded-lg" accept=".mov" />

				<p className="mx-auto w-fit pb-4">Only .mov files can be uploaded</p>

				{error && <p className="text-red-500 mt-2 mx-auto w-fit">{error}</p>}
				{saveDate && <p className="text-green-500 my-2 mx-auto w-fit">Saved at {saveDate.toLocaleTimeString()}</p>}

				<div className="relative">
					<input type="submit" className={`block mx-auto px-12 py-2 m-2 cursor-pointer transition-all bg-blue-500 hover:bg-blue-600 disabled:bg-blue-700 disabled:cursor-not-allowed rounded-lg ${isSaving ? 'text-transparent' : 'text-white'}`} disabled={!isValid} />
					{isSaving && <Loader className="w-8 h-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" fill="white" />}
				</div>
			</form>
		</div>
	);
}

export default HomePage;
