import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const signage = require('../signage.png');

export interface GSIResponse {
	clientId: string;
	credential: string;
	select_by: string;
}

interface LoginPageProps {
	callback: (data: GSIResponse) => void;
	errorText: string;
}

declare const google: {
	accounts: {
		id: {
			initialize(options: { client_id: string; callback: (data: GSIResponse) => void }): void;
			renderButton(element: HTMLElement, options: { theme: string; size: string }): void;
		}
	}
};

function LoginPage({ callback, errorText }: LoginPageProps) {
	const buttonRef = React.createRef<HTMLDivElement>();

	React.useEffect(() => {
		(async () => {
			while (typeof google === 'undefined') await new Promise(r => setTimeout(r, 100));

			google.accounts.id.initialize({
				client_id: window.location.hostname === 'localhost'
					? '903254941549-ncj8n5detl2els0n10e273kp60flo3dg.apps.googleusercontent.com' // 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
					: '903254941549-oha2n1u6cut5p1ued3mb2054sovv7uqo.apps.googleusercontent.com', // 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
				callback: callback,
			});

			if (buttonRef.current) {
				google.accounts.id.renderButton(buttonRef.current, {
					theme: 'outline',
					size: 'large',
				});
			}
		})();
	}, [typeof google]);

	return (
		<div className="w-full h-full flex items-center justify-center flex-col">
			<img src={signage} className="max-w-[50%] max-h-[16rem] m-8" alt="" />
			<h1 className="text-3xl font-bold text-blue-500">Textbook Invoicing</h1>
			<p className="my-2">Use your pensacolachs.org email</p>
			<p className="text-red-600 mb-2">{errorText}</p>

			<div>
				<div ref={buttonRef}></div>
			</div>
		</div>
	);
}

export default LoginPage;
