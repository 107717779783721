import React from 'react';
import { useStore } from '../App';

function SettingsPage() {
	const [session, logOut] = useStore(state => [state.session, state.logOut]);
	
	return (
		<React.Fragment>
			<h1 className="text-3xl font-bold ml-8">{session?.user.name ?? 'Not logged in'}</h1>
			<button className="bg-red-500 shadow rounded text-white mt-2 ml-8 px-3 py-1" onClick={logOut}>Log Out</button>
		</React.Fragment>
	);
}

export default SettingsPage;

