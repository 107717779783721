import React from 'react';
import create from 'zustand';
import { Routes, Route, Outlet, useMatch, Navigate } from 'react-router-dom';

import HomePage from './pages/home';
import LoginPage from './pages/login';
import SettingsPage from './pages/settings';
import SupportPage from './pages/support';
import PrivacyPage from './pages/privacy';
import { GSIResponse } from './pages/login';

import { BrowserTracing } from '@sentry/tracing';
import { init as sentryInit } from '@sentry/react';
import Loader from './icons/loader';

sentryInit({
	dsn: '',
	integrations: [new BrowserTracing()],
	tracesSampleRate: window.location.host.includes('localhost') ? 1.0 : 0.2,
});

export interface Session {
	id: string;
	expiresIn: number;
	user: {
		email: string;
		name: string;
	};
}

export const apiURL = (path: string) => new URL(path, window.location.hostname === 'localhost' ? 'http://localhost:3001' : 'https://api.signage.pensacolachs.org/');

interface SignageConfiguration {
	dateStyle: 'short' | 'medium' | 'long' | 'full';
	timeStyle: 'short' | 'medium' | 'long' | 'full';
	video: string;
}

interface GlobalState {
	configuration: SignageConfiguration | null;
	session: Session | null;

	getConfiguration(): Promise<SignageConfiguration>;
	setConfiguration(form: FormData): Promise<void>;
	setSession(session: Session | null): void;
	logOut(): Promise<void>;
}

export const useStore = create<GlobalState>((set, get) => ({
	configuration: null,
	session: null,

	getConfiguration() {
		return fetch(apiURL('/source'))
			.then(r => r.json())
			.then(r => {
				set({ configuration: r });
				return r;
			});
	},

	async setConfiguration(form) {
		const { session, logOut } = get();
		if (!session?.id) throw new Error('No session exists');

		await fetch(apiURL('/source'), {
			method: 'PUT',
			body: form,
			headers: {
				Authorization: `Bearer ${session.id}`,
			},
		}).then(response => {
			if (response.status === 403) {
				logOut();
			} else if (!response.ok) {
				throw new Error(`Failed to fetch configuration: ${response.statusText}`);
			}
		});
	},

	setSession(session: Session | null) {
		set({ session });
	},

	async logOut() {
		const { session } = get();
		if (!session) return console.error('Log out button visible while logged out');

		await fetch(apiURL('/session'), {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${session.id}`,
			},
		}).finally(() => {
			set({ session: null });
			localStorage.removeItem('session_id');
		});
	},
}));

function App() {
	const existingSessionID = localStorage.getItem('session_id');

	const [session, setSession, getConfiguration] = useStore(state => [state.session, state.setSession, state.getConfiguration]);
	const [isLoading, setIsLoading] = React.useState(session === null && existingSessionID !== null);
	const [errorText, setErrorText] = React.useState('');

	React.useEffect(() => {
		if (existingSessionID && !session) {
			setIsLoading(true);

			fetch(apiURL('/session'), {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${existingSessionID}`,
				},
			}).then(response => {
				if (response.status === 404 || response.status === 400) {
					localStorage.removeItem('session_id');
				} else if (response.status === 403) {
					setErrorText('This email is not associated with a user.');
				} else if (response.status === 200) {
					response
						.json()
						.then((session: Session) => {
							setTimeout(() => useStore.getState().logOut(), session.expiresIn * 1000);
							setSession(session);

							setTimeout(() => {
								getConfiguration();
							});
						});
				} else {
					setErrorText('An unknown error occurred');
					console.error('Unknown error:', response.status, response.statusText);
				}
			})
				.catch(() => {
					setErrorText('Could not contact server');
				})
				.finally(() => setIsLoading(false));
		}
	}, []);

	const callback = (response: GSIResponse) => {
		setIsLoading(true);

		fetch(apiURL('/session'), {
			method: 'PUT',
			body: response.credential,
			headers: {
				'Content-Type': 'text/plain',
			},
		})
			.then(async response => {
				if (response.status === 404 || response.status === 400) {
					localStorage.removeItem('session_id');
				} else if (response.status === 403) {
					setErrorText('This email is not associated with a user.');
				} else if (response.status === 200) {
				
					response
						.json()
						.then((r: Session) => {
							setSession(r);
							localStorage.setItem('session_id', r.id);
						});
				} else {
					setErrorText('An unknown error occurred');
					console.error('Unknown error:', response.status, response.statusText);
				}
			})
			.catch(err => {
				setErrorText('Could not contact server');
				console.error('Failed to start session:', err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Routes>
			<Route path="/" element={<Layout loading={isLoading} />}>
				<Route index element={<HomePage />} />
				<Route path="login" element={<LoginPage callback={callback} errorText={errorText} />} />
				<Route path="settings" element={<SettingsPage />} />
				<Route path="support" element={<SupportPage />} />
				<Route path="privacy" element={<PrivacyPage />} />

				<Route path="*" element={
					<div className="w-full h-full flex items-center align-center">
						<h1>Page not found</h1>
					</div>
				} />
			</Route>
		</Routes>
	);
}

function Layout({ loading }: { loading: boolean }) {
	const [logOut, session] = useStore(state => [state.logOut, state.session]);
	const isLogin = useMatch('/login') !== null;
	const isPrivacyOrSupport = useMatch('/privacy') !== null || useMatch('/support') !== null;

	if (loading) {
		return (
			<Loader fill="#00cc99" className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-16 w-16 stroke-blue-500" />
		);
	}

	if (session === null && !isLogin && !isPrivacyOrSupport) {
		console.debug('Not logged in, redirecting...');
		return <Navigate to="/login" />;
	}

	if (session !== null && isLogin) {
		console.debug('Logged in, redirecting...');
		return <Navigate to="/" />;
	}

	if (isLogin) return <Outlet />;

	return (
		<div className="grid grid-rows-[64px auto]">
			<header className="flex justify-between items-center p-4">
				<h1 className="text-2xl font-bold text-blue-500">Signage</h1>

				<nav>
					<p className="inline-block mx-2" aria-hidden>{session?.user.name}</p>
					<button className="bg-red-500 inline-block shadow rounded text-white mx-2 px-3 py-1" onClick={logOut}>Log Out</button>
				</nav>
			</header>

			<div className="min-h-[calc(100vh-64px)] min-w-full">
				<Outlet />
			</div>
		</div>
	);
}

export default App;
