import React from 'react';

function SupportPage() {
	return (
		<div className="p-8">
			<p>The signage configuration file is JSON with the following structure:</p>

			<code>
				&#123;
					&quot;video&quot;: &quot;/path/to/video/file&quot;,
					&quot;dateStyle&quot;: &quot;one of &apos;full&apos;, &apos;long&apos;, &apos;medium&apos;, &apos;short&apos;, or &apos;none&apos;&quot;,
					&quot;timeStyle&quot;: &quot;one of &apos;full&apos;, &apos;long&apos;, &apos;medium&apos;, &apos;short&apos;, or &apos;none&apos;&quot;,
					&quot;lastUpdated&quot;: 1673403966023 // timestamp of the last video update
				&#125;
			</code>

			<p className="my-4">No personal information is collected by this app. <a href="/privacy" className="text-blue-700 underline">Privacy Policy</a></p>
		</div>
	);
}

export default SupportPage;
